import React, {FC, useEffect, useMemo, useState} from 'react';
import Form from "react-bootstrap/Form";
import {TicomboEvent} from "../../../../models/TicomboEvent";
import {TicketData, TicomboFormData} from "../../../../pages/User/SellPage/SellTicketPage/Data";
import './TicomboInventoryForm.scss'
import TicomboLogo from "../../../../assets/images/ticombo/logo.png";
import InputTooltipError from "../../../Errors/InputTooltipError/InputTooltipError";

interface Props {
    ticomboFormData: TicomboFormData,
    ticomboEvent: TicomboEvent,
    sbTicketData: TicketData,
    isEditForm?: boolean
    dispatch: (column: keyof TicomboFormData, value: any) => void,
}

const TicomboInventoryForm: FC<Props> = ({
                                             ticomboEvent,
                                             ticomboFormData,
                                             sbTicketData,
                                             isEditForm,
                                             dispatch,
                                         }) => {

    isEditForm ??= false;

    const [categoryLocal, setCategoryLocal] = useState<any>(null);
    const [sectionLocal, setSectionLocal] = useState<any>(null);
    const [customPriceLocal, setCustomPriceLocal] = useState<any>(ticomboFormData.customPrice);
    const [setSectionStatusLocal, setSetSectionStatusLocal] = useState(0);
    const [shouldPushLocal, setShouldPushLocal] = useState<boolean>(ticomboFormData.shouldPush);
    const [canChangeCategory, setCanChangeCategory] = useState(true);
    const [hover, setHover] = React.useState<keyof TicomboFormData | null>(null)
    const [stockType, setStockType] = useState<null | string>(sbTicketData.stock_type);

    const onFocus = (field: keyof TicomboFormData) => setHover(field)
    const onBlur = () => setHover(null)

    const options = [
        {
            name: 'I do not know exact seat location',
            value: 0
        },
        {
            name: 'I know my exact seat location',
            value: 1
        },
    ];

    useEffect(() => {
        setShouldPush(!!ticomboFormData.shouldPush);
        setCanChangeCategory(!(isEditForm as boolean) || !ticomboFormData.category);

        if (!ticomboFormData.category) {
            ticomboEvent.ticket_types.length ?
                dispatch('category', ticomboEvent.ticket_types[0].name) :
                dispatch('category', null);
        }

        if (!ticomboFormData.fanSection) {
            ticomboEvent.venue.fanSections.length ?
                dispatch('fanSection', ticomboEvent.venue.fanSections[0].name) :
                dispatch('fanSection', null);
        }
        setStockType(sbTicketData.stock_type);
        setCategoryLocal(ticomboFormData.category);
        setSetSectionStatusLocal(ticomboFormData.setSectionStatus);
        setSectionLocal(ticomboFormData.fanSection);
        setCustomPriceLocal(ticomboFormData.customPrice);
    }, []);

    useEffect(() => {
        if (stockType !== sbTicketData.stock_type) {
            setStockType(sbTicketData.stock_type);
            // setInHandDateLocal(null);
        }
    }, [sbTicketData]);

    const validatePrice = useMemo(() => {
        return customPriceLocal
            ? Number(sbTicketData.unit_cost) > Number(customPriceLocal)
            : false
    }, [customPriceLocal, sbTicketData]);

    function setCategory(category: string) {
        setCategoryLocal(category);
        dispatch('category', category)
    }

    function setFanSection(section: string) {
        setSectionLocal(section);
        dispatch('fanSection', section)
    }

    function setSectionStatus(section: number) {
        setSetSectionStatusLocal(section);
        dispatch('setSectionStatus', section)
    }

    function setCustomPrice(section: number) {
        setCustomPriceLocal(section);
        dispatch('customPrice', section)
    }

    function setShouldPush(check: boolean) {
        setShouldPushLocal(check);
        dispatch('shouldPush', check)
    }

    return (
        <>
            <div className="ticombo-inventory" id="ticombo">
                <div className="ticombo-inventory__wrapper">
                    <div className={`ticombo-inventory__should-push ${shouldPushLocal ? '' : 'ticombo-inventory__input-checkbox_not-push'}`}>
                        <Form.Check.Input
                            className="ticombo-inventory__input-checkbox"
                            type="checkbox"
                            checked={shouldPushLocal}
                            onChange={(e) => setShouldPush(e.target.checked)}
                        />
                        <Form.Check.Label className="">Push Inventory to Ticombo</Form.Check.Label>
                    </div>
                    {shouldPushLocal &&
                        <div className="ticombo-inventory__content">
                            <h3 className={'ticombo-inventory__header'}>
                                Listing Mapping
                                <img src={TicomboLogo} alt={'ticombo-logo'}/>
                            </h3>
                            {ticomboEvent?.ticket_types?.length ?
                                <div className="ticombo-inventory__input-wrapper">
                                    <label htmlFor="ticombo-category"
                                           className={'ticombo-inventory__input-label'}>Category</label>
                                    <Form.Select id="ticombo-category"
                                                 value={categoryLocal}
                                                 disabled={!canChangeCategory}
                                                 className={'ticombo-inventory__input'}
                                                 onChange={(e) => setCategory(e.target.value)}
                                    >
                                        {
                                            ticomboEvent.ticket_types.map(category => (
                                                <option key={category.name}
                                                        value={category.name}>{category.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </div>
                                :
                                null
                            }
                            {ticomboEvent?.venue?.fanSections?.length ?
                                <div className="ticombo-inventory__input-wrapper">
                                    <label htmlFor="ticombo-fan-section" className={'ticombo-inventory__input-label'}>Fan
                                        Section</label>
                                    <Form.Select id="ticombo-fan-section"
                                                 value={sectionLocal}
                                                 className={'ticombo-inventory__input'}
                                                 onChange={(e) => setFanSection(e.target.value)}
                                    >
                                        {
                                            ticomboEvent?.venue?.fanSections.map(section => (
                                                <option key={section.name} value={section.name}>{section.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </div>
                                :
                                null
                            }
                            <div className="ticombo-inventory__input-wrapper">
                                <label htmlFor="ticombo-price" className={'ticombo-inventory__input-label'}>
                                    Custom price
                                </label>
                                <input id="ticombo-price"
                                       type={'number'}
                                       className={`ticombo-inventory__input ${validatePrice ? 'error' : ''}`}
                                       placeholder={sbTicketData.unit_cost + ''}
                                       value={customPriceLocal as any}
                                       onMouseEnter={() => onFocus('customPrice')}
                                       onMouseLeave={() => onBlur()}
                                       onChange={(e) => setCustomPrice(e.target.value as any)}
                                >
                                </input>
                                <InputTooltipError message={'Custom price should be more than unit price'}
                                                   show={() => validatePrice && hover === 'customPrice'}
                                ></InputTooltipError>
                            </div>
                            <div className="ticombo-inventory__input-ratio-wrapper">
                                {options.map((option, index) => (
                                    <div className={""}>
                                        <label key={option.name} className={"edit-event-form__result"}>
                                            <input
                                                type={"radio"}
                                                name={"event"}
                                                value={option.value}
                                                checked={setSectionStatusLocal === option.value}
                                                onChange={(event) => {
                                                    setSectionStatus(Number(event.target.value))
                                                }}
                                            />
                                            {option.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
};

export default TicomboInventoryForm;
